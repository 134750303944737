<template>
  <div class="flex-grow-1">
    <UserTitle hideBack>
      <template v-slot:actions>
        <div>
          <h2 class="font-weight-black" :class="$vuetify.breakpoint.xs ? 'text-h4' : 'text-h3'">Current Job Applications</h2>
        </div>
      </template>
    </UserTitle>

    <!-- Filters -->
    <div class="mb-15">
      <v-row>
        <v-col cols="12" md="9" class="jobs-section">
          <preloader v-if="jobStatus.getting" />
          
          <custom-alert v-if="!ordered.length && !jobStatus.getting">
            No records found.
          </custom-alert>
          
          <div v-if="ordered.length && !jobStatus.getting">
            <JobsCard v-for="job in ordered" :key="job.id" :job="job" applied />
          </div>
        </v-col>

        <v-col cols="3" class="d-none d-sm-flex">
          <div>
            <AppliesFilter @sortOrder="filterOrder" />
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- End Filters -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import AppliesFilter from "@/components/dashboard/apply/AppliesFilter"
import JobsCard from "@/views/job/JobCard"
import _orderBy from "lodash/orderBy"
import _find from "lodash/find"
import moment from 'moment'

export default {
  name: "Jobs",

  metaInfo: {
    title: "Jobs",
  },

  data() {
    return {
      confirm: true,
      deleteMessage: "Deleting message",
      deleting: false,
      toDelete: null,
      locationFilterValue: null,
      skillsFilterValue: null,
      remoteFilterValue: null,
      sortJobs: "desc",
      order: "createdAt",
      status: {
        deleting: false,
      },
      orderBy: "desc",
    }
  },

  components: {
    AppliesFilter,
    JobsCard,
  },

  computed: {
    ...mapState({
      user: (state) => state.user.user,
      jobs: (state) => state.apply.applies,
      jobApplied: (state) => state.apply.applied,
      jobStatus: (state) => state.apply.status,
    }),

    ordered: function () {
      let refined = _orderBy(this.jobs, "createdAt", this.orderBy)
      refined = refined.filter(job => this.$store.getters['apply/getApplicantStatus'](job.id) !== 'Hired')
      refined = refined.filter(job => job.status !== 'Closed')

      refined = refined.filter(job => {
        return this.$startDate(job.contractDates) > moment().valueOf()
      })

      return refined
    },
  },

  methods: {
    ...mapActions("apply", ["getApplies", "getJobApplied", "getApplied"]),

    sortDate(value) {
      this.sortJobs = value == "asc" ? "asc" : "desc"
    },

    skillsFilter(value) {
      this.skillsFilterValue = value
    },

    locationsFilter(value) {
      this.locationFilterValue = value
    },

    remoteFilter(value) {
      this.remoteFilterValue = value
    },

    filterOrder(sortData) {
      this.orderBy = sortData.order
    },

    getJobStatus(job) {
      let data = []
      if (this.jobApplied) {
        data = _find(this.jobApplied, function (applied) {
          return applied.jobId == job.id
        })
      }
      return data.status
    },
  },

  created() {
    this.getApplies()
  },
}
</script>